.container {
  width: 350px;
  height: 90px;
  flex-shrink: 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 25px 27px;
  background-color: #25262B;
  border-radius: 8px;
  border: 1px solid #373A40;
  flex-flow: row wrap;
  align-items: center;

  .leftContainer {
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: #C1C2C5;
    font-size: 18px;
    font-style: normal;
  }

  .baseButton {
    cursor: pointer;
    width: 91px;
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .connectButton {


    display: inline-flex;
    padding: 11px 19px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: linear-gradient(263deg, #00AD60 -0.41%, #1222B2 100%);

    transition: filter 0.2s ease;

    &:hover {
      filter: brightness(110%);
    }

    color: #D1E3F3;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    border: none;
  }

}


