
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #C1C2C5;
  margin-left: 1px;
  margin-bottom: 20px;
}

.item {
  display: flex;
  align-items: center;

  &__dots {
    width: 18px;
    height: 18px;
    margin-right: 15px;
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #C1C2C5;
    margin-left: 1px;
    margin-right: 12px;
  }
}
