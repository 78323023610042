.headingText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    display: inline-block;
    color: #c1c2c5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.4px;
  }
}

.slider {
  display: flex;
  align-items: center;
  &__units {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #2c2e33;
    padding: 4px;
    color: #c1c2c5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.border {
  &__submodules {
    display: "flex";
    flex-direction: column;
  }
}
