.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 10px -1rem 0;
  background: #25262B;
  border-radius: 4px;
  border: 1px solid #373A40;
}

.step {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 1px;
  border-bottom: 1px solid #373A40;

  &:last-of-type {
    border-bottom: none;
  }
}

.stepInner {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 16px 16px 16px 20px;
  color: #C1C2C5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;

  span {
    cursor: pointer;
  }

  &__passed {
    text-decoration-line: line-through;

    span {
      cursor: initial;
    }
  }
}

@media screen and (max-width: 1727px) {
  .inner {
    padding: 0;
  }

  .stepInner {
    padding: 8px 16px 8px 20px;
    font-size: 12px;
  }
}