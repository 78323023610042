.layout {
  background-color: #1a1b1e;
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
}


.subscriptionsContent {
  flex: 1;
  height: fit-content;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscriptions {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;

}

.card {
  background: #25262b;
  border: 1px solid #2c2e33;
  box-shadow:
    0 10px 10px -5px rgba(0, 0, 0, 4%),
    0 20px 25px -5px rgba(0, 0, 0, 5%),
    0 1px 3px 0 rgba(0, 0, 0, 5%);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 320px;

  &__description {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    white-space: pre-line;
  }

  &__mainInfoWrapper {
    height: 50px;
  }

  &__textWithQuestion {
    display: flex;
    gap: 2px;
    align-items: center;
  }

  &__firstInfo {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    line-height: 1.55;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }

  &__price {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
  }

  &__subscribeButton {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__list__item {
    position: relative;
  }

  &__iconInfo {
    position: absolute;
    right: 17px;
    transform: translateY(-20px);
  }
}
