.wrapper {
  width: 340px;
  height: 610px;

  &__carousel {
    height: 690px;
  }
}
.widgetWrapper {
  position: relative;
}
.widgetCenter {
  position: absolute;
  top: 50%;
}
