.mainContainer {
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  overflow-y: scroll;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.noVideoContainer {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: 110%;
}

.noVideoBtn {
  cursor: pointer;
  background: linear-gradient(270deg, #00ad60 -2.21%, #001eff 153.81%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.row {


 &__item {
   width: var(--card-width);
   display: flex;
 }
}
