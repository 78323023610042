.dropzone {
  flex: 1;
  padding-bottom: 50px;
  color: #C1C2C5;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: transparent;
  }
}

.uploadVideos {
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.uploadButtonContainer {
  pointer-events: all;
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  .uploadButton {
    width: 250px;
    background-color: #00AD60;
  }
}

.noVideoText {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 110%;
}

.noVideoContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
