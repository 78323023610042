.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #2c2e33;
}

.menu {
  &__target {
    display: flex;
    align-items: center;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 550px;
      white-space: nowrap;
      display: inline-block;
      margin-right: 8px;
      color: #c1c2c5;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24.8px;
    }
  }
}

.dropdown {
  background-color: #25262b;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #373a40;
  margin-left: -7px;
  &__item {
    display: flex;
    align-items: center;
    color: #c1c2c5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    &:hover {
      background: rgba(23, 232, 133, 20%);
      color: #25262b;
    }
  }
}
.deleteBtn {
  &__text {
    color: #e03131;
  }
}

.iconDef {
  color: #c1c2c5;
}
.disabledIcon {
  path {
    stroke: #5c5f66;
  }
}
.disabledText {
  color: #5c5f66;
}

.previewBtn {
  border-color: #17e885;
  color: #17e885;
}
