.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  nav {
    height: 100%;
  }
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.scrollableAreaContent {
  padding: 42px 60px;
}
