.root {
  max-width: 960px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

.label {
  text-align: center;
  font-weight: 900;
  font-size: 38px;
  line-height: 1;
  margin-bottom: 48px;
  color: rgb(233, 236, 239);

  @media (max-width: 720px) {
    font-size: 32px;
  }
}

.description {
  max-width: 500px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 48px;
}

.title {
  text-align: center;
  font-weight: 900;
  font-size: 38px;
  color: rgb(201, 201, 201);
}

.bottomGroup {
  justify-content: center;
}

.errorNumber {
  color: hsla(0, 0%, 30%, 0.35);
}
