.wrapper {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 12px 12px 12px 21px;
  border-bottom: 1px solid #2C2E33;
  box-sizing: border-box;

  &.active {
    background-color: rgba(23, 232, 133, 0.2);
  }
}
