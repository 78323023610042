.grip {
  width: 18px;
  height: 18px;
}

.gripHandler {

  width: 18px;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  left: -18px;
  top: 4px;
  position: absolute;
}

.videoCardWrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    object-fit: cover;
    width: 100%;
    margin-left: 13px;
}

.videoCard {
  width: 100%;
  display: flex;
  flex: 1;
}
