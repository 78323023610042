.title {
  color: #c1c2c5;
  font-size: 18px;
  font-style: normal;
  margin: 19px 0 22px 16px;
}
.filter {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 16px 24px 0;
  background: #25262b;
  border: none;

  &__accordionLabel {
    color: #C1C2C5;
    display: flex;
    height: 44px;
    align-items: center;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
  }

  &__item {
    color: #C1C2C5;
    display: flex;
    justify-content: space-between;
    height: 44px;
    padding: 14px 16px;
    align-items: center;
    border-radius: 0 8px 8px 0;
    cursor: pointer;

    &:hover {
      background-color: #373a40;
    }
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    span {
      color: #C1C2C5;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 44px;
      display: block;
    }
    &Active {
      color: #fff;
      background-color: rgba(23, 232, 133, 20%);
      &:hover {
        background-color: rgba(23, 232, 133, 20%);
      }
    }
  }

  .accordingFilter{
    border-left: 1px solid #373a40;
  }
}
