.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.loginContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-top: 80px;

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
.title {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 65px;
  margin-top: unset;
}
.icon {
  color: #c1c2c5;
}

.reset {
  padding: 0;
  border: none;
  background: transparent;
}
