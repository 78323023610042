.container {
  display: flex;
  flex-direction: column;

  &__border {
    border-bottom: 1px solid #373a40;
    &:last-child {
      border-bottom: none;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    padding: 12px 0 12px 12px;
  }
  &__submodule {
    padding-left: 24px;
  }
}
.propName {
  color: #c1c2c5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.4px;
  display: inline-block;
  margin-right: 8px;

  &__withoutMarging {
    margin-right: 0;
  }
}
.spaceBetween {
  justify-content: space-between;
}
.column {
  align-items: flex-start;
  flex-direction: column;
}
.bottomSpace {
  margin-bottom: 12px;
}

.submodule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 12px;
  }
  &__container {
    padding-top: 8px;
    display: "flex";
    flex-direction: column;
  }
  &__title {
    color: #c1c2c5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.4px;
  }
}
