.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.header {
  max-width: 550px;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 75px;
  &__marginTop{
    margin-top: 80px;
  }
}
.types {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 45px;
}
.type {
  border-radius: 8px;
  border: 1px solid #2c2e33;
  background: #25262b;
  box-shadow:
    0 10px 10px -5px rgba(0, 0, 0, 4%),
    0 20px 25px -5px rgba(0, 0, 0, 5%),
    0 1px 3px 0 rgba(0, 0, 0, 5%);
  &__preview {
    height: 257px;
    width: 353px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    img {
      transform: translate(-20px, -5px) scale(1.01);
    }
  }
  &__btnWrapper {
    display: flex;
    justify-content: center;
    padding: 22px 0;
  }
}
