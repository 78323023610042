.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 12px 13px 32px;
  background-color: rgba(23, 232, 133, 20%);
  width: 100%;
  height: 60px;
}

.btn {
  color: #fff;
  padding: 7px;
  height: 36px;
  margin-right: 8px;
  background-color: #25262b;
  &:hover {
    background-color: #373a40;
  }
  &:last-child {
    margin-right: 0;
  }
  &__cancel {
    border: 1px solid #373a40;
    width: 84px;
  }
  &__delete {
    background-color: #3e2324;
  }
  &__container {
    display: flex;
    align-items: center;
  }
}

.icon {
  height: 20px;
  width: 20px;
  path {
    stroke: #fff;
  }
  &__delete {
    path {
      stroke: #f7cbca;
    }
  }
}
