.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 65px;
  margin-top: 70px;
}
.icon {
  color: #c1c2c5;
}

.reset {
  padding: 0;
  border: none;
  background: transparent;
}
