.videoList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Each card has a max width of 255px and fills the available space */
  gap: 16px; /* Space between items */
  width: 100%;

}

.videoCard {
  padding: 8px;
  text-align: center;
  box-sizing: border-box;
}

