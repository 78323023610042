$icon-shadow-primary: 0 1px 5px rgba(0, 0, 0, 0.65);
$icon-shadow-outline: 0 0 1px rgba(0, 0, 0, 0.6);

.menu {
  margin-top: 3px;
}

.dropdown {
  background-color: #25262b;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #373a40;
  margin-left: -7px;

  &__item {
    display: flex;
    align-items: center;
    color: #c1c2c5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &:hover {
      background: rgba(122, 171, 148, 20%);
      color: white;
    }
  }
}

.deleteBtn {
  &__text {
    color: #e03131;
  }
}

.menuIcon {
  color: #fff;
  cursor: pointer;
  filter: drop-shadow($icon-shadow-primary);
  filter: drop-shadow($icon-shadow-outline);
}
