.wrapper {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px 7px;
   color: #A6A7AB;
   border-radius: 8px;
   cursor: pointer;

   &:hover, &:focus {
       background-color: #373A40;

       .list {
            display: block;
       }
   }
}

.list {
     position: absolute;
     top: -10px;
     left: 0;
     z-index: 10;
     display: none;
     width: 332px;
     padding-left: 84px;
}
