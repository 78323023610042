.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #2c2e33;
  background: #1a1b1e;
  padding: 0 16px;
}
.startContent {
  justify-content: flex-start;
}
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  color: #c1c2c5;
  font-family: "Segoe UI", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  cursor: pointer;

  &__icon {
    width: 14px;
    height: 18px;
    margin-left: 5px;
    margin-top: 2px;
  }
  &__down {
    width: 14px;
    height: 18px;
    margin-left: 5px;
    margin-top: 5px;
    transform: rotate(180deg);
  }
}
.dropdown {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  &__item {
    background: #373a40;
    color: #c1c2c5;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.7px;
    text-align: center;
    &:hover {
      background-color: #25262b;
      color: #c1c2c5;
    }
  }
}
.header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.sort {
  border-radius: 4px;
  border: 1px solid #373a40;
  background: #25262b;
  display: inline-flex;
  height: 36px;
  padding: 8px 7px 9px 13px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  margin-right: 25px;
  margin-left: 18px;
}
