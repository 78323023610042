.wrapper {
  display: flex;
  align-items: center;
  gap: 14px;
}

.amountText {
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.8px;
}
