.root {
  cursor: pointer;
  pointer-events: auto;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.preview {

  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: transparent;

  &.hidden {
    opacity: 0;
  }

  &__skeleton {
    display: block !important;
  }

  &__playStopButton{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
