.panel {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.menu_item {
  background-color: #1a1b1e;
  height: 44px;
  color: #a6a7ab;
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
}
.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__text {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__del {
    margin-left: 5px;
    opacity: 0.1;
    transition: 0.3s !important;
    outline: none;
    padding: 10px;
    animation-iteration-count: 1;

    button &:hover,
    &:hover {
      color: #282c34;
    }
  }

  &__loading {
    opacity: 0.3;
    transition: 0.3s !important;
    outline: none;
    padding: 5px;
    animation-iteration-count: 1;
    cursor: default;
  }

  &:hover {
    .link__del {
      transition: 0.3s;
      z-index: 5;
      animation-iteration-count: 1;
      opacity: 1;
    }
  }

  &__active {
    color: #001eff;
  }
}

.bottomActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.account {
  border-top: 1px solid #2c2e33;
}
.linkIcon {
  display: flex;
  align-items: center;
}
.button {
  display: flex;
  align-items: center;
  background-color: #1a1b1e;
  padding: 0 12px;
  height: 44px;
  font-family: "Segoe UI", sans-serif;
  color: #a6a7ab;
  font-size: 14px;
  &__icon {
    margin-right: 12px;
  }
}
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.collapseIcon {
  color: #a6a7ab;
  margin-left: 9px;
  margin-bottom: 48px;
  transition-duration: 300ms;
}
.divi {
  background-color: aliceblue;
}
