.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid #373a40;
  border-radius: 8px;
  background-color: #25262b;
  height: 90px;
  width: 350px;
  &:hover {
    background-color: #373a40;
  }
  &__title {
    color: #c1c2c5;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.9px;
    margin-left: 8px;
  }
}

.header {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}
