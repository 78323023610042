.layout {
  min-height: 100vh;
  display: flex;
  background-color: #1a1b1e;
}

.content {
  background: #1a1b1e;
  flex: 1;
}

.contentInner {
  height: 100%;
  width: 100%;
  margin: 0;
  padding-top: 10px;
  transition: width 0.1s linear;

  nav {
    background-color: #1a1b1e;
    border-right: none;
    margin: 0;
  }

  &__close {
    padding-top: 10px;
    background-color: #1a1b1e;
    height: 100%;
    width: 100%;
    transition: width 0.1s linear;

    nav {
      background-color: #1a1b1e;
      border-right: none;
      margin-left: 8px;
    }

    button {
      padding: 10px 7px;
      border-radius: 8px;
    }
  }
}

.sidebar {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 16px;
  background: #1a1b1e;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #2c2e33;

  &__close {
    height: 100%;
    width: 60px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #2c2e33;
  }
}

.logoHeader {
  display: flex;
  align-items: center;
  background-color: #1a1b1e;
  border-bottom: 1px solid #373a40;
  position: relative;
  height: 60px;

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1b1e;
    border-bottom: 1px solid #1a1b1e;
    position: relative;
    height: 60px;
  }
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.logo {
  flex-shrink: 1;
  object-fit: cover;
  object-position: 0 0;
}

.noCollapsed {
  position: absolute;
  bottom: 132px;
  left: 283px;
  z-index: 5;
  cursor: pointer;
  transition: left 0.1s linear;
}

.collapsed {
  position: absolute;
  bottom: 132px;
  left: 43px;
  z-index: 5;
  cursor: pointer;
  transition: left 0.1s linear;
}

.close {
  display: none;
}

.collapseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #1a1b1e;
  border-radius: 100%;
  box-shadow: 0 0 4px 2px rgba(161, 158, 161, 30%);
}

.logoWrapper {
  margin-top: 7px;
}