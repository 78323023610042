.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  border-bottom: none;

  .mantine-UnstyledButton-root {
    &:hover {
      background-color: transparent;
    }

    .mantine-Accordion-label {
      margin-right: -18px;
    }
  }
}
