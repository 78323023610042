.select {
  width: 320px;
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  &__label {
    color: #c1c2c5;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.7px;
  }
}
