.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  text-decoration: none;
  width: 300px;
  border: 1px solid #1877F2;
  background-color: #1877F2;
  border-radius: 8px;
  transition: background-color 0.2s ease, transform 0.2s ease;
  cursor: pointer;
}

.linkConnected {
  border-color: white;
  background-color: black;
}

.icon {
  width: 24px;
  height: 24px;
  color: white;
  margin-right: 8px;

}

.text {
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Roboto', 'Freight Sans LF Pro', Helvetica, Arial, 'Lucida Grande', sans-serif;
  color: #fff;
}
