.title {
  color: #c1c2c5;
  font-size: 18px;
  font-style: normal;
  margin: 19px 0 22px 16px;
}
.filter {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #25262b;
  border: none;
  border-left: 1px solid #373a40;
  &__item {
    display: flex;
    justify-content: space-between;
    height: 42px;
    padding: 14px 16px;
    align-items: center;
    border-radius: 0 8px 8px 0;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: #373a40;
    }
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    span {
      color: #c1c2c5;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px;
      display: block;
    }
    &Active {
      background-color: rgba(23, 232, 133, 20%);
      &:hover {
        background-color: rgba(23, 232, 133, 20%);
      }
    }
  }
}
