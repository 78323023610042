
.wrapper {
  position: relative;
  margin-bottom: 30px;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dropzone {
  flex: 1;
  border-width: 1px;
  padding-bottom: 50px;
  background-color: #25262B;

  &[data-accept] {
    border: 1px dashed #373A40;
    background-color: #25262B;
    flex: 1;
  }

  &:hover {
    background-color: #25262B;
  }
}

.uploadVideos {
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #C1C2C5;
}

.subText {
  margin-top: 30px;
  font-family: 'Segoe UI', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;

  color: #909296;
}

.icon {
  color: var(--mantine-color-gray-4);
}

.control {
  position: absolute;
  width: 250px;
  left: calc(50% - 125px);
  bottom: -20px;
  background-color: #00AD60;
}

.modalHeader {
  display: none;
}

.modalContent {
  padding: 0;
  background-color: transparent;
  max-width: 700px;
  min-width: 700px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.modalInner {
  //background-color: #373A40;
}

.modalBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: transparent;
}
