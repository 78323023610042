.wrapper {
  overflow: visible;
  width: 288px;
  padding-top: 0;
  border-radius: unset;
  border-radius: 8px;
  cursor: pointer;

  .title {
    margin-top: 12px;
    color: #c1c2c5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: calc(28px * 1);
  }
}

.menu {
  margin-top: 3px;
}

.downblock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.preview {
  height: 198px;
  color: #c1c2c5;
  position: relative;
  background: #343a40;
  border-radius: 8px 8px 0 0;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 18px;

  &__widgetName {
    color: #c1c2c5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dropdown {
  background-color: #25262b;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #373a40;
  margin-left: -7px;

  &__item {
    display: flex;
    align-items: center;
    color: #c1c2c5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &:hover {
      background: rgba(23, 232, 133, 20%);
      color: #25262b;
    }
  }
}

.deleteBtn {
  &__text {
    color: #e03131;
  }
}
