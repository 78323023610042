.wrapper {
    pointer-events: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 12px;

  .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > * {
      pointer-events: auto;
    }

    .checkbox {
        input {
          cursor: pointer;
          &:not(:checked){
            border: 1px solid white;
            background-color: transparent;
          }

          &:checked {
            border: 1px solid transparent;
          }
        }
    }
  }
}
