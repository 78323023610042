.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.pageContent {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
