.control {
  &:hover {
    background-color: unset;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  color: #A6A7AB;
}

.counter {
  font-size: 14px;
  font-weight: 600;
  color: #C1C2C5;
}

.progress {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 0 calc(1rem + 0.25rem);
  margin-top: -4px;
}

.progressItem {
  flex: 1;
  height: 6px;
  background-color: #00AD60;
  opacity: 0.6;
  border-radius: 32px;
  box-shadow: 0 7px 7px -5px rgba(0, 0, 0, 4%), 0 10px 15px -5px rgba(0, 0, 0, 5%), 0 1px 3px 0 rgba(0, 0, 0, 5%);

  &__passed {
    background-color: #17E885;
    opacity: 1;
  }
}

@media screen and (max-width: 1727px) {
  .title {
    font-size: 14px;
    line-height: 115%;
  }

  .counter {
    font-size: 12px;
  }
}