.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  margin-top: 35px;
  margin-bottom: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 18px;
  font-style: normal;
  margin: 19px 0 22px 16px;
  color: #c1c2c5;
}

.hidden {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.analyticsIframe {
  width: calc(100% - 30px);
  flex: 1;
}
